
import { defineComponent } from '@vue/runtime-core'
import { DefaultTableExposed } from 'apptimizm-ui'
import { Ref, computed, ref } from 'vue'
import Table from './table.vue'
import { store } from '@/store/'

export default defineComponent({
  setup () {
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const isProjectSelected = computed(() => !!store.currentProject.id)

    const tabValue = ref<'id'|'tsd'>('id')

    const tableFilter = {
      search: ref(''),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id
        if (this.search.value) res.search = this.search.value

        return res
      }
    }

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Роли</h1>
          <div class="page-top-panel-row mt-5">
            <div class="tab-list">
              <button
                class={{ 'tab-item tab-item-left': true, active: tabValue.value === 'tsd' }}
                onClick={() => { tabValue.value = 'tsd' }}
              >ТСД</button>
              <button
                class={{ 'tab-item tab-item-right': true, active: tabValue.value === 'id' }}
                onClick={() => { tabValue.value = 'id' }}
              > ID </button>
            </div>
            <button class="button-save right-item">Отчёт по ТСД</button>
            <button class="button-save">Отчёт итог</button>
          </div>
        </div>
        <div class="layout">
          {isProjectSelected.value ? <Table table={table} tableFilter={tableFilter} tabValue={tabValue}/> : null}
        </div>
      </div>
    )
  }
})
